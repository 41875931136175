.header__container {
  position: sticky;
  top: 0;
  z-index: 1;
  transition: all 0.2s ease-in;
}

.header__container--hidden {
  opacity: 0;
}

.mobile-menu {
  display: none;
}

.mobile-menu__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  transition: all 0.2s ease-in;
  transform: translateY(-50%);
  visibility: hidden; /* add this line */
  opacity: 0;
}

.mobile-menu__container--open {
  transform: translateY(70px);
  visibility: visible; /* add this line */
  opacity: 1;
  color: white;
}

.category-expand-transition {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  max-height: 0;
}

.category-expand-transition.open {
  max-height: 2000px; /* Set to a value that's surely more than the content's height */
}

@media screen and (max-width: 600px) {
  .mobile-menu {
    display: block;
  }

  .desktop-menu {
    display: none;
  }
}
