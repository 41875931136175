/* src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Apply the primary brand color and neutral grays */
:root {
  --primary-color: #0065ff;
  --text-dark: #333333;
  --text-light: #aaaaaa;
  --background-white: #ffffff;
}

/* Typography */
body {
  font-family: "Open Sans", sans-serif;
  /* color: var(--text-dark); */
  background-color: var(--background-white);
}

/*h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  color: var(--primary-color);
}

h1 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}

p {
  font-size: 1rem;
  line-height: 1.6;
  color: var(--text-dark);
}

.secondary-text {
  color: var(--text-light);
}
*/
